import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInWeeks } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { BottomSheet } from '../../../components/bottom-sheet/BottomSheet'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { Dialog } from '../../../components/general/Dialog'
import { PageHeader } from '../../../components/general/PageHeader'
import { ContentContainer } from '../../../components/page-layout'
import { BodySmallRegular, H6 } from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'
import { HomeChargingSessionCard } from '../../home/components/HomeChargingSessionCard'
import { ChargeModeSwitcher } from '../components/ChargeModeSwitcher/ChargeModeSwitcher'
import { EnergyConsumptionCarCard } from '../components/EnergyConsumptionCarCard'
import { EnergyConsumptionPeakCard } from '../components/EnergyConsumptionPeakCard'
import { EnergyTotalConsumptionCard } from '../components/EnergyTotalConsumptionCard'
import { HemsScreenSkeleton } from '../components/HemsScreenSkeleton'
import { useHems } from '../hooks/useHems'
import { HemsLostConnectionCard } from '../onboarding/components/HemsLostConnectionCard'
import { HemsIntroduction } from '../onboarding/components/introduction/HemsIntroduction'
import { HemsProvider } from '../providers/HemsProvider'

export const HEMSScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <HemsProvider>
      <ContentContainer>
        <PageHeader>{t('employee.hems.title')}</PageHeader>
        <HemsOverviewScreen />
      </ContentContainer>
    </HemsProvider>
  )
}

const HemsOverviewScreen = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    setPoints,
    setPointsLoading,
    handleCompleteIntroduction,
    energyConsumption,
    energyConsumptionLoading,
    dongleStatus,
  } = useHems()
  const { user } = useUser()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { currentMember } = useMember()
  const { trackEvent } = useTracking()

  const [showIntroduction, setShowIntroduction] = useState(false)
  const [rejectedIntroduction, setRejectedIntroduction] = useState(false)

  const userHasHemsConfigured = !!user.addresses[0].scoptOnboarded
  const userHasScoptId = user.addresses[0].scoptId !== null
  const userHasHcp = user.addresses.some(
    (address) => address.devices?.length > 0
  )

  const showIntroductionCard =
    !rejectedIntroduction &&
    user.addresses[0].scoptOnboarded &&
    !user.addresses[0].scoptIntroduced &&
    differenceInWeeks(new Date(), new Date(user.addresses[0].scoptOnboarded)) <
      1

  // -- Render --
  if (
    (!setPoints && setPointsLoading) ||
    (!energyConsumption && energyConsumptionLoading)
  ) {
    return <HemsScreenSkeleton />
  }

  if (!userHasHemsConfigured) {
    return <Navigate to={EmployeeRoutes.Root} replace />
  }

  return (
    <>
      <StGrid>
        <StColumn>
          {dongleStatus === 'online' ? null : <HemsLostConnectionCard />}

          {showIntroductionCard && (
            <div>
              {isDesktop && (
                <StSectionH6>{t('employee.hems.overview.new')}</StSectionH6>
              )}
              <StWelcomeContainer>
                <StWelcomeContent>
                  <StWelcomeTitle>
                    {t('employee.hems.overview.welcome.title')}
                  </StWelcomeTitle>
                  <BodySmallRegular>
                    {t('employee.hems.introduction.intro')}
                  </BodySmallRegular>
                  <BodySmallRegular>
                    {t('employee.hems.introduction.intro-estimation')} ・ 2 min.
                  </BodySmallRegular>
                  <ButtonPrimary onClick={() => setShowIntroduction(true)}>
                    {t('employee.hems.introduction.intro-action')}
                  </ButtonPrimary>
                </StWelcomeContent>
                <StClose
                  onClick={() => {
                    trackEvent(EventType.Submit, 'reject_hems_introduction')
                    handleCompleteIntroduction()
                    setRejectedIntroduction(true)
                  }}
                >
                  <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
                </StClose>
              </StWelcomeContainer>
            </div>
          )}
          {userHasHcp && userHasHemsConfigured && userHasScoptId ? (
            <div>
              <HomeChargingSessionCard member={currentMember} />
            </div>
          ) : null}
          <div>
            <StSectionH6>
              {t('employee.hems.charge-mode.home-charger-title')}
            </StSectionH6>
            <ChargeModeSwitcher />
          </div>
        </StColumn>
        <StColumn>
          <StUsageSection>
            <StSectionHeader>
              <StSectionH6>
                {t('employee.hems.overview.usage.title')}
              </StSectionH6>
              <StBodySmallRegular>
                {t('employee.hems.overview.usage.title-this-month')}
              </StBodySmallRegular>
            </StSectionHeader>
            <StCard>
              <StFlex>
                <StSectionGrid>
                  <EnergyTotalConsumptionCard />
                  <EnergyConsumptionPeakCard />
                  <StEnergyConsumptionCarCard />
                </StSectionGrid>
                <ButtonPrimary
                  onClick={() => navigate(EmployeeRoutes.UsageDetails)}
                >
                  {t('employee.hems.overview.usage.action.insights')}
                </ButtonPrimary>
              </StFlex>
            </StCard>
          </StUsageSection>
        </StColumn>
      </StGrid>

      {isDesktop ? (
        <Dialog
          width="700px"
          height="700px"
          open={showIntroduction}
          onOpenChange={(open) => setShowIntroduction(open)}
          showHeader={false}
        >
          <HemsIntroduction onClose={() => setShowIntroduction(false)} />
        </Dialog>
      ) : (
        <BottomSheet
          isOpen={showIntroduction}
          onClose={() => setShowIntroduction(false)}
          hasBackdrop
          hideHeader
        >
          <HemsIntroduction onClose={() => setShowIntroduction(false)} />
        </BottomSheet>
      )}
    </>
  )
}

const StGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    column-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
    grid-template-columns: repeat(2, 1fr);

    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StSectionH6 = styled(H6)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

const StCard = styled.div`
  background-color: ${({ theme }) => theme.theme.colors.white};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

const StSectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

const StSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: center;

  & > ${StSectionH6} {
    margin: 0;
  }
`

const StBodySmallRegular = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

// Welcome card
const StWelcomeContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
`

const StWelcomeTitle = styled(H6)`
  margin: 0;
`

const StWelcomeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  flex-grow: 1;

  white-space: pre-line;
`

const StClose = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.UI.SpacingPx.Space2};
  right: ${({ theme }) => theme.UI.SpacingPx.Space5};

  cursor: pointer;

  color: ${({ theme }) => theme.components.menu.wizard['item-icon']};

  border-radius: 100px;

  font-size: ${({ theme }) => theme.UI.SpacingPx.Space4};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  width: ${({ theme }) => theme.UI.SpacingPx.Space10};

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  z-index: 100;

  &:hover {
    background-color: #0000001c;
  }

  @media ${breakpoints.mobile} {
    margin-right: -${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StEnergyConsumptionCarCard = styled(EnergyConsumptionCarCard)`
  grid-column: span 2;
`

const StUsageSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`
