import { Urls } from '../../../api/urls'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'
import { DocumentsDataTable } from '../components/DocumentsDataTable'

export const DocumentsScreen = () => {
  return (
    <DataTableProvider
      paginationType={PaginationType.None}
      url={Urls.fetchDocuments}
      defaultSort="name"
      defaultSortDirection={SortKeys.ASCENDING}
    >
      <DocumentsDataTable />
    </DataTableProvider>
  )
}
