import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Chart } from '../../../components/chart/Chart'
import { formatTooltip, formatData } from '../../../core/lib/chart'
import { formatDecimals } from '../../../core/utils/number'

import { StChartContainer, StChartInfoContainer } from './UsageDetails'
import { UsageDetailsInfoCard } from './UsageDetailsInfoCard'

import type { DateRangeMode } from './UsageDetails'
import type { ActiveElement } from 'chart.js'

type UsageDetailsDynamicTariffProps = {
  usageDetails?: {
    dynamicPrice?: [string, number][]
  }
  dateRange: { start: Date; end: Date }
  dateRangeMode: DateRangeMode
  isEnabled?: boolean
}

export function UsageDetailsDynamicTariff({
  usageDetails,
  dateRange,
  dateRangeMode,
}: UsageDetailsDynamicTariffProps) {
  // -- Hooks --
  const theme = useTheme()
  const { t } = useTranslation()

  // -- State --
  const [selectedPrice, setSelectedPrice] = useState<ActiveElement | null>(null)

  // -- Graph data --
  const priceData = useMemo(() => {
    // Map the data to the format expected by formatData
    const mappedData = (usageDetails?.dynamicPrice ?? []).map((data) => ({
      label: data[0],
      value: data[1] / 1000, // Convert to kWh
    }))

    // Then format using the chart helper
    const formattedData = formatData(dateRange, dateRangeMode, mappedData, t)

    // Fill in the 15-minute intervals with the hourly value
    return formattedData.map((point, index) => {
      if (point.value !== 0) return point

      // Find the last non-zero value in the same hour
      const hourStart = Math.floor(index / 4) * 4
      const hourPoints = formattedData.slice(hourStart, hourStart + 4)
      const hourValue = hourPoints.find((p) => p.value !== 0)?.value || 0

      return {
        ...point,
        value: hourValue,
      }
    })
  }, [dateRange, dateRangeMode, t, usageDetails?.dynamicPrice])

  // -- Chart --
  const lineChart = useMemo(
    () => (
      <Chart<'line'>
        type="line"
        data={{
          labels: priceData.map((data) => data.label),
          datasets: [
            {
              data: priceData.map((data) => data.value),
              animation: { duration: 0 },
              tension: 0,
              stepped: 'before',
              fill: false,
              backgroundColor: theme.theme.colors['nonary-5'] + '20',
              borderWidth: 2,
              borderColor: theme.theme.colors['nonary-5'],
            },
          ],
        }}
        onSelectedDataPointsChange={(elements) => {
          setSelectedPrice(elements?.[0] ?? null)
        }}
        processTooltipData={(elements) => {
          if (!elements?.length) return ''

          return formatTooltip(
            dateRangeMode,
            t,
            priceData[elements[0].index].timestamp
          )
        }}
      />
    ),
    [priceData, theme.theme.colors, dateRangeMode, t]
  )

  const averagePrice = useMemo(() => {
    if (priceData.length === 0) return 0
    const totalPrice = priceData.reduce(
      (accumulator, current) => accumulator + (current.value ?? 0),
      0
    )
    return totalPrice / priceData.length
  }, [priceData])

  return (
    <StChartContainer>
      <StChartInfoContainer>
        <UsageDetailsInfoCard
          selected={!!selectedPrice}
          value={
            selectedPrice
              ? formatDecimals(priceData[selectedPrice.index].value ?? 0, 2)
              : formatDecimals(averagePrice, 2)
          }
          suffix="€/kWh"
          color={theme.theme.colors['primary-0']}
          label={selectedPrice ? 'Stroomprijs' : 'Gem. Stroomprijs'}
        />
      </StChartInfoContainer>

      {lineChart}
    </StChartContainer>
  )
}
