import { type Member } from 'database'
import { isPast, startOfTomorrow } from 'date-fns'
import { Form, Formik } from 'formik'
import { useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { ButtonSecondary } from '../../../components/button/ButtonSecondary'
import { DateInput } from '../../../components/form/date-input/DateInput'
import { Input } from '../../../components/form/Input'
import { H5 } from '../../../components/typography'
import { shallowEqual } from '../../../core/lib/shallowEqual'

import type { UserWithContractAndCards } from 'types'

type PracticalInfoCardProps = {
  employee: UserWithContractAndCards
  refetchEmployee: () => void
}

export const PracticalInfoCard = ({
  employee,
  refetchEmployee,
}: PracticalInfoCardProps) => {
  // -- State --
  const [editable, setEditable] = useState<boolean>(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Data --
  const [{ data, loading }, updateEmployee] = useAuthMemberAxios<Member>(
    {
      url: replaceParametersInPath(Urls.employeeUpdatePracticalData, {
        id: employee.id,
      }),
      method: 'PATCH',
    },
    { manual: true }
  )

  // -- Handlers --
  const handleOpen = () => {
    setEditable(true)
    trackEvent(EventType.Click, `edit_employee_practical_data`)
  }

  const handleClose = () => {
    setEditable(false)
    trackEvent(EventType.Click, `cancel_edit_employee_practical_data`)
  }

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await updateEmployee({
        data: {
          internalId: values.internalId,
          activationDate: values.activationDate,
        },
      })
      trackEvent(EventType.Submit, `update_employee_practical_data`)

      refetchEmployee()

      setEditable(false)
    } catch {
      toast.error(t('profile.personal-data.error'))
    }
  }

  // -- Vars --
  const activationDate = useMemo(() => {
    // Member Activation date will always be set
    return data?.activationDate || employee.members[0].activationDate || ''
  }, [data?.activationDate, employee.members])

  const initialValues = useMemo(
    () => ({
      internalId: data?.internalId || employee.members[0].internalId || '',
      activationDate: activationDate,
      blossomId: employee.blossomId,
      visualNumber: employee.members[0]?.mspCards[0]?.visualNumber || '',
    }),
    [activationDate, data?.internalId, employee.blossomId, employee.members]
  )

  const mspStatus = employee.members[0].mspStatus
  const hcpStatus = employee.hcpStatus.status

  const mspRegistered = ['Registered', 'Inapplicable'].includes(mspStatus)
  const hcpRegistered = ['Registered', 'Inapplicable'].includes(hcpStatus)

  const disableDateEdit = useMemo(() => {
    if (isPast(new Date(activationDate))) return true

    const disabledByMsp = !mspRegistered
    const disabledByHcp = !hcpRegistered

    return disabledByMsp && disabledByHcp
  }, [activationDate, mspRegistered, hcpRegistered])

  const maxDate = useMemo(() => {
    // If user is still registered for everything, we don't want to limit the date
    if (mspRegistered && hcpRegistered) return undefined

    // If the user only has hcp the date can only be changed to an earlier date
    if (!hcpRegistered && mspStatus === 'Inapplicable')
      return new Date(activationDate)

    // If the user only has msp the date can only be changed to an earlier date
    if (!mspRegistered && hcpStatus === 'Inapplicable')
      return new Date(activationDate)

    // If the user has started hcp but not msp the user can only change to an earlier date
    if (!hcpRegistered && mspStatus === 'Registered')
      return new Date(activationDate)
  }, [activationDate, mspRegistered, hcpRegistered, mspStatus, hcpStatus])

  // -- Render --
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        handleChange,
        errors,
        values,
        touched,
        handleBlur,
        resetForm,
        setFieldValue,
      }) => (
        <StForm>
          <StHeaderContainer>
            <H5>{t('profile.personal-data.practical')}</H5>
            {editable ? (
              <StButtonContainer>
                <ButtonPrimary
                  compact
                  type="submit"
                  icon={['fasr', 'floppy-disk']}
                  iconAlignment="left"
                  disabled={shallowEqual(initialValues, values) || loading}
                  size="sm"
                >
                  {t('profile.personal-data.save')}
                </ButtonPrimary>
                <ButtonPrimary
                  compact
                  type="button"
                  onClick={() => {
                    resetForm()
                    handleClose()
                  }}
                  icon={['fasr', 'xmark-large']}
                  iconAlignment="left"
                  disabled={loading}
                  size="sm"
                >
                  {t('profile.personal-data.cancel')}
                </ButtonPrimary>
              </StButtonContainer>
            ) : (
              <ButtonSecondary
                type="button"
                compact
                onClick={handleOpen}
                icon={['fasr', 'pen']}
                iconAlignment="left"
                size="sm"
              >
                {t('profile.personal-data.edit')}
              </ButtonSecondary>
            )}
          </StHeaderContainer>
          <Input
            name="internalId"
            label={t('profile.personal-data.practical.internal-id')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.internalId}
            touched={touched.internalId}
            value={values.internalId}
            readOnly={!editable}
            disabled={loading}
            placeholder="-"
          />
          <DateInput
            label={t('profile.personal-data.practical.activation-date')}
            onChange={(value) => setFieldValue('activationDate', value)}
            shortCutType="short-term"
            readOnly={!editable}
            disabled={disableDateEdit}
            value={new Date(values.activationDate)}
            minDate={startOfTomorrow()}
            maxDate={maxDate}
          />
          <Input
            name="blossomId"
            label={t('profile.personal-data.practical.blossom-id')}
            value={values.blossomId!}
            onChange={() => {}}
            readOnly={!editable}
            disabled
            placeholder="-"
          />
          <Input
            name="displayName"
            label={t('profile.personal-data.practical.visual-number')}
            value={values.visualNumber!}
            onChange={() => {}}
            readOnly={!editable}
            disabled
            placeholder="-"
          />
        </StForm>
      )}
    </Formik>
  )
}

const StForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;

  position: relative;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  grid-template-columns: 1fr 1fr;

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  h5 {
    margin: 0;
  }

  grid-column: span 2;
`

const StButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
