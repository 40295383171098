import ReactContentLoader from 'react-content-loader'
import { useTheme } from 'styled-components'

import type React from 'react'

export function SkeletonContentLoader({
  viewBox,
  children,
}: ContentLoaderProps) {
  const theme = useTheme()

  return (
    <ReactContentLoader
      speed={2}
      viewBox={viewBox}
      foregroundColor={theme.theme.colors['nonary-8']}
      backgroundColor={theme.theme.skeleton.default}
    >
      {children}
    </ReactContentLoader>
  )
}

type ContentLoaderProps = {
  children: React.ReactNode
  viewBox: React.SVGAttributes<SVGElement>['viewBox']
}
