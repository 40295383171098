import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { css, styled } from 'styled-components'

import empty from '../../components/assets/empty.svg'
import { Skeleton } from '../../components/Skeleton'
import {
  BodyMediumRegular,
  BodySmallRegularCss,
  H5,
} from '../../components/typography'
import { useGetDateFnsLocale } from '../../core/hooks/useGetDateFnsLocale'
import { useDataTable } from '../hooks/useDataTable'
import { columnValue } from '../utils/columnValue'

import { ColumnType, type BaseRow, type Column } from './DataTable'

type DataTableBodyProps<T> = {
  columns: Column<T>[]
  data?: T[]
  onRowClick?: (dataRow: T) => void
  hrefGenerator?: (dataRow: T) => string
  loading?: boolean
  hasFilters?: boolean
  emptyTitle: string
  emptyDescription: string
  emptyFiltersTitle: string
  emptyFiltersDescription: string
  emptyImage?: string
}

export const DataTableBody = <T extends BaseRow>({
  columns,
  data,
  onRowClick,
  hrefGenerator,
  hasFilters = false,
  loading = false,
  emptyTitle,
  emptyDescription,
  emptyFiltersTitle,
  emptyFiltersDescription,
  emptyImage,
}: DataTableBodyProps<T>) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { amount } = useDataTable()

  if (loading) {
    return (
      <StBodyContainer>
        {Array.from({ length: amount }).map((_, index) => (
          <StTableRowLoading key={'loader_' + index} $disabled>
            <StTableCellLoading colSpan={columns.length}>
              <Skeleton height={40} />
            </StTableCellLoading>
          </StTableRowLoading>
        ))}
      </StBodyContainer>
    )
  }

  if (!data?.length) {
    return (
      <StBodyContainer>
        <StTableRow $disabled={true}>
          <td colSpan={999}>
            <StEmptyContainer>
              <img src={emptyImage ?? empty} alt="empty state" />
              <StEmptyContent>
                <H5>{hasFilters ? emptyFiltersTitle : emptyTitle}</H5>
                <BodyMediumRegular>
                  {hasFilters ? emptyFiltersDescription : emptyDescription}
                </BodyMediumRegular>
              </StEmptyContent>
            </StEmptyContainer>
          </td>
        </StTableRow>
      </StBodyContainer>
    )
  }

  return (
    <StBodyContainer>
      {data.map((dataRow) => (
        <StTableRow
          $disabled={onRowClick === undefined && hrefGenerator === undefined}
          key={dataRow.id}
          onClick={() => onRowClick?.(dataRow)}
        >
          {columns.map((column) => {
            if (column.type === ColumnType.ACTION) {
              return (
                <StTableCell $hasLink={false} key={column.key}>
                  {column.icon && (
                    <StColumnAction
                      $disabled={column.disabled}
                      onClick={(event) => {
                        event.stopPropagation()
                        column.onClick && !column.disabled
                          ? column.onClick(dataRow)
                          : null
                      }}
                      icon={column.icon}
                    />
                  )}
                </StTableCell>
              )
            }

            const cellContent = (
              <>
                {column.prepend}
                {columnValue(column, dataRow, dateFnsLocale)}
                {column.append}
              </>
            )

            return (
              <StTableCell $hasLink={!!hrefGenerator} key={column.key}>
                {hrefGenerator ? (
                  <StCellLink to={hrefGenerator(dataRow)}>
                    {cellContent}
                  </StCellLink>
                ) : (
                  cellContent
                )}
              </StTableCell>
            )
          })}
        </StTableRow>
      ))}
    </StBodyContainer>
  )
}

const StBodyContainer = styled.tbody``

const StTableRow = styled.tr<{ $disabled?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};

  cursor: pointer;

  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ $disabled, theme }) =>
      $disabled ? 'inherit' : theme.theme.colors['nonary-9']};
  }
`

const StTableRowLoading = styled(StTableRow)`
  border-bottom: none;
`

const StTableCell = styled.td<{ $hasLink?: boolean }>`
  ${BodySmallRegularCss}
  text-align: left;

  height: ${({ theme }) => theme.UI.SpacingPx.Space16};
  padding: 0;

  ${({ $hasLink }) =>
    !$hasLink &&
    css`
      padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space3}`};

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    `}
`

const StTableCellLoading = styled(StTableCell)`
  padding: ${({ theme }) => `${theme.UI.SpacingPx.Space3} 0`};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

const StCellLink = styled(Link)`
  padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space3}`};
  height: ${({ theme }) => theme.UI.SpacingPx.Space16};

  display: grid;
  align-items: center;

  color: initial;

  &:first-child {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 10px;
  }
`

const StEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space15} ${theme.UI.SpacingPx.Space7}`};
`
const StEmptyContent = styled.div`
  text-align: center;

  h5 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
  }

  > * {
    margin: 0;
  }
`

const StColumnAction = styled(FontAwesomeIcon)<{ $disabled?: boolean }>`
  ${BodyMediumRegular};

  color: ${({ theme, $disabled }) =>
    $disabled ? theme.theme.colors['nonary-6'] : theme.theme.colors.black};

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  pointer-events: all;

  &:hover {
    color: ${({ theme, $disabled }) =>
      $disabled
        ? theme.theme.colors['nonary-6']
        : theme.theme.colors['nonary-4']};
  }
`
