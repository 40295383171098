// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { useAuthMemberAxios } from '../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../api/urls'
import { downloadBlob } from '../lib/downloadBlob'

import type { AxiosResponse } from 'axios'

export const useDownloadZohoFile = (fileId?: string, forUserId?: string) => {
  // -- State --
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<Blob>()
  const [currentFileId, setCurrentFileId] = useState<string>()

  // -- Hooks --
  const { t } = useTranslation()

  // -- Data --
  const [, executeGetFile] = useAuthMemberAxios<Blob>(
    {},
    {
      manual: true,
      cache: true,
    }
  )

  // -- Effects --
  useEffect(() => {
    if (fileId) {
      downloadFileData(fileId, forUserId)
    }
  }, [fileId])

  // -- Functions --
  const downloadFileData = async (fileId: string, forUserId?: string) => {
    const response = await executeGetFile({
      url: `${Urls.ZohoPhoto}/File/${fileId}`,
      method: 'GET',
      responseType: 'blob',
      params: { forUserId },
    })

    setFile(response.data)

    return response
  }

  const download = async (
    fileName: string,
    fileId?: string,
    download: boolean = true,
    extension?: string,
    forUserId?: string
  ) => {
    setLoading(true)

    try {
      let response: AxiosResponse<any> | undefined = undefined

      // Always download new file if fileId is different
      if (fileId !== currentFileId) {
        if (fileId) {
          response = await downloadFileData(fileId, forUserId)
        }
        setCurrentFileId(fileId)
      } else if (!file && fileId) {
        response = await downloadFileData(fileId, forUserId)
      }

      const fileToDownload = response?.data ?? file
      // Derive the extension (defaults to '.pdf' if none given)
      const fileExtension = extension ? `.${extension.toLowerCase()}` : '.pdf'

      // Actually do the download
      downloadBlob(fileToDownload, fileName, fileExtension, download)
    } catch {
      toast.error(t('error.downloadFile'))
    }

    setLoading(false)
  }

  return {
    download,
    loading,
    file,
  }
}
