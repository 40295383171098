import { createContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EmployeeRoutes } from '../../../../routing/routes'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { Wizard } from '../../../components/navigation/Wizard'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'
import {
  hcpOnboardingWizardConfig,
  simplifiedOnboardingWizardConfig,
} from '../config/wizardConfig'
import { useOnboardingState } from '../hooks/useOnboardingState'
import { ChargerLocationKeys } from '../steps/preferences/ChargerLocationStep'
import {
  Amperage,
  GridConnection,
} from '../steps/preferences/ConnectionTypeStep'
import { PrivateTerrainKeys } from '../steps/preferences/PrivateTerrainStep'
import { WallMaterialKeys } from '../steps/preferences/WallMaterialStep'
import { RenderOnboardingStep } from '../steps/RenderOnboardingStep'
import { getConnectionMethodName } from '../util/product'

import type { SelectBoxesItems } from '../components/SelectBoxes'
import type { OnboardingValues } from '../hooks/useOnboardingState'
import type { Dispatch, SetStateAction } from 'react'
import type { ConnectionMethod, Product } from 'types'

type GridConnectionOption = {
  key: GridConnection
  label: string
}

type OnboardingContext = {
  handleNext: (data: OnboardingValues, alternative?: boolean) => void
  setLoading: (loading: boolean) => void
  loading: boolean
  values: OnboardingValues
  setValues: Dispatch<SetStateAction<OnboardingValues>>
  simplifiedFlow: boolean
  products: {
    data?: Product[]
    loading?: boolean
  }
  items: {
    chargerLocationItems: SelectBoxesItems<ChargerLocationKeys>[]
    privateTerrainItems: SelectBoxesItems<PrivateTerrainKeys>[]
    wallMaterialItems: SelectBoxesItems<WallMaterialKeys>[]
    manufacturers?: string[]
    connectionMethods?: ConnectionMethod[]
    gridConnectionOptions: GridConnectionOption[]
    amperageOptions: {
      key: Amperage
      label: string
    }[]
  }
}

export const onboardingContext = createContext<OnboardingContext | null>(null)

export const OnboardingScreen = () => {
  const { user } = useUser()
  const { user: auth0User } = useAuthentication()

  const { currentMember } = useMember()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    currentStep,
    headerState,
    handleNext,
    handlePrevious,
    setLoading,
    loading,
    showSaved,
    values,
    onboardingIsLoading,
    setValues,
    simplifiedFlow,
    error,
  } = useOnboardingState()

  const [{ data: products, loading: loadingProducts }] = useAuthMemberAxios<
    Product[]
  >({
    url: Urls.productAll,
  })

  // -- Functions --
  const getUniqueValues = (array?: string[]) => {
    return array?.filter(
      (value: string, index: number, array: string[]) =>
        array.indexOf(value) === index && value !== '' && value !== null
    )
  }

  // -- Data --
  const chargerLocationItems: SelectBoxesItems<ChargerLocationKeys>[] = [
    {
      name: t('onboarding.location.garage'),
      icon: ['fasr', 'garage'],
      key: ChargerLocationKeys.Garage,
    },
    {
      name: t('onboarding.location.outdoor'),
      icon: ['fasr', 'house-tree'],
      key: ChargerLocationKeys.Outdoor,
    },
    {
      name: t('onboarding.location.carport'),
      icon: ['fasr', 'car-garage'],
      key: ChargerLocationKeys.Carport,
    },
    {
      name: t('onboarding.location.underground'),
      icon: ['fasr', 'house-blank'],
      key: ChargerLocationKeys.Underground,
    },
    {
      name: t('onboarding.location.else'),
      icon: ['fasr', 'ellipsis'],
      key: ChargerLocationKeys.Other,
    },
  ]

  const privateTerrainItems: SelectBoxesItems<PrivateTerrainKeys>[] = [
    {
      name: t('onboarding.private-terrain.yes'),
      subTitle: t('onboarding.private-terrain.yes.subtitle'),
      icon: ['fasr', 'check'],
      key: PrivateTerrainKeys.Yes,
    },
    {
      name: t('onboarding.private-terrain.permission-needed'),
      subTitle: t('onboarding.private-terrain.permission-needed.subtitle'),
      icon: ['fasr', 'file-check'],
      key: PrivateTerrainKeys.Permission,
    },
    {
      name: t('onboarding.private-terrain.no'),
      subTitle: t('onboarding.private-terrain.no.subtitle'),
      icon: ['fasr', 'xmark'],
      key: PrivateTerrainKeys.No,
    },
  ]

  const manufacturers = useMemo(() => {
    return getUniqueValues(
      products?.map((product: Product) => product.manufacturer)
    )
  }, [products])

  const connectionMethods: ConnectionMethod[] | undefined = useMemo(
    () =>
      products
        ?.reduce(
          (result: ConnectionMethod[], product: Product) => [
            ...result,
            ...product.connectionMethods,
          ],
          []
        )
        // Filter out duplicates
        .filter(
          (value: ConnectionMethod, index: number, array: ConnectionMethod[]) =>
            array.findIndex((method) => method.id === value.id) === index
        )
        .sort((a: ConnectionMethod, b: ConnectionMethod) =>
          getConnectionMethodName(a, user.language).localeCompare(
            getConnectionMethodName(b, user.language)
          )
        ),
    [products, user.language]
  )

  const wallMaterialItems: SelectBoxesItems<WallMaterialKeys>[] = [
    {
      name: t('onboarding.installation-material.ideal.title'),
      subTitle: t('onboarding.installation-material.ideal.subtitle'),
      icon: ['fasr', 'block-brick'],
      key: WallMaterialKeys.Ideal,
    },
    {
      name: t('onboarding.installation-material.not-ideal.title'),
      subTitle: t('onboarding.installation-material.not-ideal.subtitle'),
      icon: ['fasr', 'block'],
      key: WallMaterialKeys.NotIdeal,
    },
  ]

  const gridConnectionOptions = useMemo<GridConnectionOption[]>(
    () => [
      {
        key: GridConnection['Single Phase 230V'],
        label: t('onboarding.connection-type.grid-connection.single-phase'),
      },
      {
        key: GridConnection['Triple Phase 230V'],
        label: t('onboarding.connection-type.grid-connection.triple-phase'),
      },
      {
        key: GridConnection['Triple Phase 400V with Neutral'],
        label: t(
          'onboarding.connection-type.grid-connection.triple-phase-with-neutral'
        ),
      },
    ],
    [t]
  )

  const amperageOptions = useMemo(
    () => [
      {
        key: Amperage['16 A'],
        label: t('onboarding.connection-type.amperage.sixteen'),
      },
      {
        key: Amperage['25 A'],
        label: t('onboarding.connection-type.amperage.twenty-five'),
      },
      {
        key: Amperage['32 A'],
        label: t('onboarding.connection-type.amperage.thirty-two'),
      },
      {
        key: Amperage['40 A'],
        label: t('onboarding.connection-type.amperage.fourty'),
      },
      {
        key: Amperage['50 A'],
        label: t('onboarding.connection-type.amperage.fifty'),
      },
      {
        key: Amperage['64 A'],
        label: t('onboarding.connection-type.amperage.sixty-four'),
      },
    ],
    [t]
  )

  // -- Effects --

  // Redirect to the home screen if the user already finished onboarding
  useEffect(() => {
    if (
      (!['Inapplicable', 'Invited', 'Onboarding'].includes(
        // If the has completed the HCP flow
        user.hcpStatus.status
      ) ||
        // OR the user does not have an HCP status
        // AND the user has completed the MSP Flow
        // (status not Inapplicable AND agreed terms === personal data step completed)
        (user.hcpStatus.status === 'Inapplicable' &&
          currentMember.mspStatus !== 'Inapplicable' &&
          user.acceptedTerms)) &&
      // AND the user has verified their email
      auth0User?.email_verified
    ) {
      navigate(EmployeeRoutes.Root, { replace: true })
    }
  }, [])

  if (onboardingIsLoading) {
    return <FullPageLoader />
  }

  if (error) {
    throw new Error('Failed to load onboarding data')
  }

  return (
    <onboardingContext.Provider
      value={{
        setValues,
        handleNext,
        setLoading,
        loading,
        values,
        simplifiedFlow,
        products: {
          data: products,
          loading: loadingProducts,
        },
        items: {
          chargerLocationItems,
          privateTerrainItems,
          manufacturers,
          connectionMethods,
          wallMaterialItems,
          gridConnectionOptions,
          amperageOptions,
        },
      }}
    >
      <Wizard
        steps={
          simplifiedFlow
            ? simplifiedOnboardingWizardConfig
            : hcpOnboardingWizardConfig
        }
        currentStep={headerState.state}
        onGoBack={headerState.cantGoBack ? undefined : handlePrevious}
        loading={loading}
        showSaved={showSaved}
        canCancel={!headerState.cantCancel}
        canLogout={headerState.canLogout ?? false}
        logoIsLink={false}
      />

      <RenderOnboardingStep
        currentStepState={headerState.state}
        currentStep={currentStep}
      />
    </onboardingContext.Provider>
  )
}
