import ContentLoader from 'react-content-loader'
import { useTheme } from 'styled-components'

export function Skeleton({
  height,
  width = '100%',
  circle = false,
}: SkeletonProps) {
  const theme = useTheme()

  const borderRadius = theme.UI.Spacing.Space2

  const circleProps = circle
    ? { cx: height / 2, cy: height / 2, r: height / 2 }
    : {}

  return (
    <ContentLoader
      speed={2}
      width={circle ? height : width}
      height={height}
      foregroundColor={theme.theme.colors['nonary-8']}
      backgroundColor={theme.theme.skeleton.default}
    >
      {circle ? (
        <circle {...circleProps} />
      ) : (
        <rect
          x="0"
          y="0"
          rx={borderRadius}
          ry={borderRadius}
          width="100%"
          height={height}
        />
      )}
    </ContentLoader>
  )
}

type SkeletonProps = {
  height: number
  width?: string | number
  circle?: boolean
}
