import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { FilterTypes } from '../../../components/filters/Filters'
import { ContentContainer } from '../../../components/page-layout'
// eslint-disable-next-line import/order
import { BodyMediumSemiBoldCss, H4 } from '../../../components/typography'

import { useDownloadZohoFile } from '../../../core/hooks/useDownloadZohoFile'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'

import type { Filter } from '../../../components/filters/Filters'
import type { Column, BaseRow } from '../../../dataTable/components/DataTable'

export enum DocumentType {
  Contract = 'Contract',
  Invoice = 'Invoice',
  Offer = 'Offer',
  Other = 'Other',
}

export enum DocumentFileType {
  PDF = 'PDF',
  DOCX = 'DOCX',
  DOC = 'DOC',
  TXT = 'TXT',
  XLSX = 'XLSX',
  XLS = 'XLS',
  PPTX = 'PPTX',
  PPT = 'PPT',
}

type Document = BaseRow & {
  id: string
  name: string
  type: DocumentType
  fileType: DocumentFileType
  fileName?: string
  fileId?: string
  added: string
}

// Add dot component
const UnreadDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.theme.colors['quaternary-1']};
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`

export const DocumentsDataTable = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { dataCount } = useDataTable()

  // Initialize with undefined - we'll pass the fileId when downloading
  const { download } = useDownloadZohoFile()

  const handleDownload = useCallback(
    (document: Document) => {
      const fileName = document.fileName || ''
      const fileType = fileName.split('.').pop()?.toLowerCase()
      download(document.name, document.fileId as string, true, fileType)
    },
    [download]
  )

  // Create filter options for document types
  const typeFilterOptions = useMemo(() => {
    const values: { label: string; values: string[] }[] = [
      {
        label: t('employer.documents.type.contract'),
        values: [DocumentType.Contract],
      },
      {
        label: t('employer.documents.type.invoice'),
        values: [DocumentType.Invoice],
      },
      {
        label: t('employer.documents.type.offer'),
        values: [DocumentType.Offer],
      },
      {
        label: t('employer.documents.type.other'),
        values: [DocumentType.Other],
      },
    ]

    return values.map((value) => ({
      key: value.values[0],
      label: value.label,
    }))
  }, [t])

  const columns = useMemo<Column<Document>[]>(
    () => [
      {
        key: 'name',
        label: t('employer.documents.name'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (row) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UnreadDot />
            {row.name}
          </div>
        ),
      },
      {
        key: 'added',
        label: t('employer.documents.date'),
        type: ColumnType.STRING,
        sortable: true,
      },
      {
        key: 'type',
        label: t('employer.documents.type'),
        type: ColumnType.CHIP,
        sortable: true,
      },
      {
        key: 'fileType',
        label: t('employer.documents.fileType'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (row) => {
          const fileName = row.fileName?.toString() || ''
          return fileName.split('.').pop()?.toUpperCase() ?? ''
        },
      },
      {
        key: 'download',
        label: '',
        type: ColumnType.ACTION,
        icon: ['fasr', 'file-download'],
        onClick: (document) => handleDownload(document),
      },
    ],
    [handleDownload, t]
  )

  const filters = useMemo<Filter[]>(() => {
    return [
      {
        key: 'type',
        placeholder: t('employer.documents.type'),
        type: FilterTypes.Multi,
        options: typeFilterOptions,
      },
      {
        key: 'date',
        placeholder: t('employer.documents.date'),
        type: FilterTypes.DateRange,
      },
    ]
  }, [typeFilterOptions, t])

  // -- Render --
  return (
    <ContentContainer>
      <StContainer>
        <StHeader>
          <StTitle>
            {t('employer.documents.title')}
            <StTitleBadge>
              {dataCount.itemCount &&
              dataCount.itemCount !== dataCount.filteredItemCount
                ? `${dataCount.filteredItemCount ?? 0}/${dataCount.itemCount}`
                : dataCount.itemCount ?? ''}
            </StTitleBadge>
          </StTitle>
        </StHeader>

        <DataTable<Document>
          columns={columns}
          filters={filters}
          emptyTitle={t('employer.documents.empty.title')}
          emptyDescription={t('employer.documents.empty.description')}
          emptyFiltersTitle={t('employer.documents.empty.filters.title')}
          emptyFiltersDescription={t(
            'employer.documents.empty.filters.description'
          )}
        />
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: 70px;
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StTitleBadge = styled.span`
  ${BodyMediumSemiBoldCss}
  background-color: ${({ theme }) => theme.theme.colors['primary-1']};
  border-radius: 999px;
  padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space3}`};

  font-size: 24px;

  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  min-width: ${({ theme }) => theme.UI.SpacingPx.Space10};

  display: grid;
  place-items: center;
`

const StTitle = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
