import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { P, match } from 'ts-pattern'

import { Skeleton } from '../../../components/Skeleton'
import { useHems } from '../../hems/hooks/useHems'
import { useHomeChargingSession } from '../hooks/useHomeChargingSession'

import { ActiveChargingSessionCard } from './ActiveChargingSessionCard'
import { PausedByCarChargingSessionCard } from './PausedByCarChargingSessionCard'
import { PausedByChargerChargingSessionCard } from './PausedByChargerChargingSessionCard'
import { UnpluggedChargingSessionCard } from './UnpluggedChargingSessionCard'

import type { FullMember } from 'types'

type HomeChargingSessionStatus =
  | 'unplugged'
  | 'charging'
  | 'paused-by-car'
  | 'paused-by-charger'

export function HomeChargingSessionCard({
  member,
}: HomeChargingSessionCardProps) {
  const { t } = useTranslation()

  const { data: homeChargingSession, loading: loadingHomeChargingSession } =
    useHomeChargingSession()
  const { setPoints, setPointsLoading } = useHems()

  if (
    (loadingHomeChargingSession && !homeChargingSession) ||
    (setPointsLoading && !setPoints)
  ) {
    return <Skeleton height={142} />
  }

  if (!homeChargingSession || !setPoints) {
    return null
  }

  const status = match(homeChargingSession)
    .returnType<HomeChargingSessionStatus>()
    .with({ status: P.string.startsWith('Charging') }, () => 'charging')
    .with(
      { status: P.string.startsWith('SuspendedEVSE') },
      () => 'paused-by-charger'
    )
    .with({ status: P.string.startsWith('SuspendedEV') }, () => 'paused-by-car')
    .otherwise(() => 'unplugged')

  const homeSessionCustomerName =
    homeChargingSession.isGuestSession === true
      ? t('employee.chargingSessions.guest')
      : member.user.firstName ?? member.user.lastName ?? ''

  return match(status)
    .with('unplugged', () => <UnpluggedChargingSessionCard />)
    .with('paused-by-car', () => (
      <PausedByCarChargingSessionCard
        customerName={homeSessionCustomerName}
        kWh={homeChargingSession.session!.kWh}
        startTime={parseISO(homeChargingSession.session!.time_started_session)}
        mode={setPoints.user_setting_mode}
      />
    ))
    .with('paused-by-charger', () => (
      <PausedByChargerChargingSessionCard
        customerName={homeSessionCustomerName}
        kWh={homeChargingSession.session!.kWh}
        startTime={parseISO(homeChargingSession.session!.time_started_session)}
        mode={setPoints.user_setting_mode}
      />
    ))
    .with('charging', () => (
      <ActiveChargingSessionCard
        customerName={homeSessionCustomerName}
        kWh={homeChargingSession.session!.kWh}
        startTime={parseISO(homeChargingSession.session!.time_started_session)}
        mode={setPoints.user_setting_mode}
      />
    ))
    .otherwise(() => null)
}
type HomeChargingSessionCardProps = {
  member: FullMember
}
