import { styled } from 'styled-components'
import { useScrollLock } from 'usehooks-ts'

import { Skeleton } from '../../components/Skeleton'

export function DataListItemSkeleton() {
  useScrollLock()

  return (
    <StContainer>
      <Skeleton width={80} height={20} />
      <Skeleton height={94} />
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
