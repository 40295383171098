export enum UnauthenticatedRoutes {
  Root = '/login',
  Debug = '/debug',
  LoginError = '/login-error',
  Invite = '/invite/:inviteId',

  ImageUpload = '/image-upload/:token',

  Map = '/embedded/map',
}

const employerRoot = '/dashboard'

export enum EmployeeRoutes {
  Root = '/',
  Refunds = '/refunds',
  HEMS = '/hems',
  Usage = '/usage',
  UsageDetails = '/usage-details',
  Map = '/map',

  ChargeCard = '/charge-card',
  ChargeCardLandingPage = '/msp/:activationCode',
  BlockChargeCard = '/charge-card/block',
  Onboarding = '/onboarding',

  Quote = '/quote',
  Peak = '/charging-peak',

  // Profile
  Profile = '/profile',
  PersonalDetails = '/profile/user',
  ChargingStation = '/profile/charger',
  Privacy = '/profile/privacy',

  // Settings
  Settings = '/profile/settings',
  GeneralSettings = '/profile/settings/general',
  SmartChargingSettings = '/profile/settings/smart-charging',
}

export enum EmployerRoutes {
  Root = `${employerRoot}`,

  Employees = `${employerRoot}/employees`,
  EmployeeDetail = `${employerRoot}/employees/:blossomId`,

  Usage = `${employerRoot}/usage`,
  InviteEmployees = `${employerRoot}/invite-employees`,
  Invoices = `${employerRoot}/invoices`,
  InvoiceDetail = `${employerRoot}/invoices/:id`,
  Contracts = `${employerRoot}/contracts`,
  Documents = `${employerRoot}/documents`,
  Settings = `${employerRoot}/settings`,
  Privacy = `${employerRoot}/privacy`,
}
